<template>
  <div class="container">
    <div class="invitaion_top" ref="invitaionTop">
      <div class="invitaion_router" @click="goBack">
        <img src="../assets/played_left.png" class="invitaion_back" alt="">
        <span class="invitaion_title">我的邀请码</span>
      </div>
    </div>
    <div class="invitaion_center">
      <span class="invitaion_center_txt">您的专属邀请码</span>
      <span class="invitaion_center_code">{{code}}</span>
      <span class="invitaion_copy" @click="goCopy">点击复制</span>
      <div class="invitaion_line"></div>
      <div class="invitaion_center_des">
        <span class="invitaion_center_des_title">活动规则 :</span>
        <span class="invitaion_center_des_txt">1. 分享邀请码给好友，对方输入邀请码，充值1笔且绑定手机号：</span>
        <span class="invitaion_center_des_txt"> - 每次成功邀请一位好友，您可以获得2小时活动卡（2小时内充值返利5% *注: 不包含省钱卡-月卡，季卡，年卡）；</span>
        <span class="invitaion_center_des_txt"> - 好友将获得5个糖豆（价值5元）。</span>
        <span class="invitaion_center_des_txt">2. 恶意套现礼包券，将即日起停止返利服务。乐糖游戏将保留追究法律责任的权力。</span>
        <span class="invitaion_center_share" @click="onShare">分享游戏</span>
      </div>
      <div class="invitaion_get" @click="onGet">已获得奖励详情</div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { ksort } from "@/utils/ksort";
import { Toast } from 'vant';

import {
  askInvitationCode
} from "@/api";

export default {
  data() {
    return {
      code: ""
    }
  },
  beforeRouteEnter(to,from,next) {
    next(vm => {
      // vm.code = ""
      var params = {
        token: vm.CYBoxUser.loginToken,
        time: Math.floor(Date.now() / 1000)
      }
      params.sign = ksort(params, 'qsdhajasfas123sq')
      askInvitationCode(params).then(res => {
        if(res.code == 1) {
          vm.code = res.data.code
        }
      })
    })
  },
  mounted() {
    if (judgeBigScreen()) {
      this.$refs.invitaionTop.className = "invitaion_top invitaionTopAndroid";
    }
  },
  methods: {
    goBack() {
      store.commit("incrementOut");
      // store.commit("tabbarIn");
      this.$router.go(-1);
    },
    goCopy() {
      var oInput = document.createElement('input');
      oInput.value = this.code;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy");
      oInput.style.display = 'none'
      document.body.removeChild(oInput)
      Toast('内容已复制');
    },
    onShare() {
      try {
        window.CYEventCenter.CYShare("https://www.2a3a.com/?code=" + this.code);
      } catch (error) {}
      try {
        window.webkit.messageHandlers.CYShare.postMessage("https://www.2a3a.com/?code=" + this.code);
      } catch (error) {}
    },
    onGet() {
      store.commit('incrementIn')
      this.$router.push('/reward')
    }
  },
  computed: {
    ...mapGetters([
      "CYBoxUser"
    ]),
  },
}
</script>
<style lang="stylus" scoped>
@import '../components/common.styl';
.invitaion_top
  background: url('../assets/top.png') center;
  background-size: 100% 100%;
  height: calc(242px + constant(safe-area-inset-bottom));
  height: calc(242px + env(safe-area-inset-bottom));
  position: relative;
  display flex
  justify-content center
.invitaion_router
  height 42px
  display flex
  align-items center
  position relative
  width: 100%;
  justify-content: center;
.invitaion_back
  position absolute
  height 20px
  width 12px
  top calc(12px + constant(safe-area-inset-bottom))
  top calc(12px + env(safe-area-inset-bottom))
  left 16px
.invitaion_title
  color #fff
  font-size 20px
  position absolute
  top calc(10px + constant(safe-area-inset-bottom))
  top calc(10px + env(safe-area-inset-bottom))
.invitaion_center
  width 86%
  background #fff
  display flex
  flex-direction column
  border-radius 20px
  margin: 0 auto;
  text-align: center;
  position absolute
  left 7%
  top 12%
.invitaion_center_txt
  font-size 18px
  margin-top 44px
.invitaion_center_code
  height 30px
  font-size 26px
  color #ee3737
  margin 10px 0 14px
.invitaion_copy
  height 30px
  line-height 30px
  width 126px
  border-radius 15px 
  border 1px solid #ee3737
  color #ee3737
  text-align center
  margin: 0 auto;
.invitaion_line
  width 100%
  border-top 1px dashed #cccccc
  margin-top: 38px;
.invitaion_center_des
  display flex
  flex-direction column
  text-align: left;
  padding-top: 20px;
.invitaion_center_des_title
  padding-left: 20px;
  font-size 16px
  font-weight 600
  margin-bottom 10px
.invitaion_center_des_txt
  padding-left: 20px;
  font-size 14px
  color #606060
  margin-bottom 2px
  padding-right: 10px;
.invitaion_center_share
  width 132px
  height 36px
  border-radius 18px
  color #fff
  line-height 36px
  text-align center
  background linear-gradient(90deg, $themeMin 45%, $themeMax 100%)
  margin 40px auto
.invitaion_get
  width 132px
  height 36px
  line-height 36px
  background #d2d2d2
  color #ff3838
  font-size 14px
  border-radius 18px
  position absolute
  bottom -48px
  right -18px
</style>